<template>
  <el-dialog
    :visible.sync="inShow"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title">
      {{ formData.id ? "编辑" : "添加" }}
    </div>
    <el-form :model="formData" label-position="left" label-width="100px">
      <el-form-item label="方案名称" required>
        <el-select
          @change="recommendChange"
          value-key="insureProgrammeId"
          v-model="formData"
          placeholder="请选择"
        >
          <el-option
            v-for="item in recommendList"
            :key="item.insureProgrammeId"
            :label="item.insureProgrammeName"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户端名称">
        <el-input disabled v-model="formData.clientDisplayName"></el-input>
      </el-form-item>
      <el-form-item label="保险公司">
        <el-input disabled v-model="formData.companyName"></el-input>
      </el-form-item>
      <el-form-item label="排序" required>
        <el-input
          v-model.number="formData.sort"
          type="number"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dl-btn-text">
      <el-button>重置</el-button>
      <el-button class="dl-confirm-btn" @click="submit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addRecommendCalculate, getRecommendCalculateList } from "@/api/policy";
export default {
  name: "addOrEditRecomDialog",
  //   model: {
  //     event: 'update',
  //     prop: 'show'
  //   },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    isedit: {
      type: Boolean,
      default: false,
    },
    itemData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      inShow: false,
      formData: {
        id: "",
        enteredName: "",
        insureProgrammeId: "",
        sort: "",
        companyName: "",
        clientDisplayName: "",
      },
      recommendList: [],
    };
  },
  watch: {
    itemData: {
      immediate: true,
      handler(n) {
        if (n) {
          this.formData = n;
        } else {
          this.formData = {};
        }
      },
    },
    inShow(n) {
      this.$emit("update:show", n);
    },
    show(n) {
      if (n) {
        getRecommendCalculateList().then(
          (res) => {
            this.recommendList = res.data;
          },
          (err) => {
            this.recommendList = [];
          }
        );
      }
      this.inShow = n;
    },
  },
  methods: {
    close() {
      this.formData = {};
    },
    recommendChange(item) {},
    submit() {
      addRecommendCalculate(this.formData).then((res) => {
        this.inShow = false;
        this.$emit("submit");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/style/Standard.scss";
</style>
