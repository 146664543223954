<template>
  <div>
    <GeneralTable ref="GeneralTable"
      tableName="RecommendDocumentTable"
      :dataList="dataList"
      :column="column"
      :option="option"
      :totalNum="totalNum"
      @add-change="handleEdit()"
      @search-change="handleSearch"
      @del-change="handleDel"
      @export-data="handleExport">
      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          @click="handleEdit(item)">编辑</el-button>
        <el-button @click="handleDel([item])"
          type="text">删除</el-button>
      </template>
    </GeneralTable>
    <addOrEditRecomDialog :show.sync="addEditShow"
      :itemData="currentItem"
      @submit="$refs.GeneralTable.getList()"></addOrEditRecomDialog>
  </div>

</template>

<script>
import { getRecommendList, exportRecommendList, deletRecommendByIds } from "@/api/policy";
import GeneralTable from "@/components/GeneralTable/index.vue";
import addOrEditRecomDialog from "./components/addOrEditRecomDialog.vue";
export default {
  name: 'InsuredSchemeManage',
  components: { GeneralTable, addOrEditRecomDialog },
  data() {
    return {
      addEditShow: false,
      dataList: [],
      totalNum: 0,
      currentItem: null,
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "名称",
          prop: "insureProgrammeName",
          width: 280,
          isShow: true,
        },
        {
          label: "客户端名称",
          prop: "clientDisplayName",
          width: 280,
          isShow: true,
        },
        {
          label: "保险公司",
          prop: "companyName",
          width: 280,
          isShow: true,
        },
        {
          label: "录入员",
          prop: "enteredName",
          width: 280,
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          width: 280,
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          width: 280,
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 220,
          fixed: "right",
          isSlot: true,
        },
      ],
      option: {
        isAdd: true, //添加 事件 @add-change
        isDel: true, //删除 @del-change
        isEdit: true, // 编辑 @edit-change
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        keyword: 'fastBlur',
        searchList: [
          {
            label: "方案名称",
            prop: "insureProgrammeName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "保险公司",
            prop: "companyName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "录入员",
            prop: "enteredName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "创建时间",
            formType: "daterange",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            dateFormat: true,
            prop: ["createTimeBegin", "createTimeEnd"],
          },
          {
            label: "更新时间",
            formType: "daterange",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            dateFormat: true,
            prop: ["updateTimeBegin", "updateTimeEnd"],
          }
        ],
      },

    };
  },
  methods: {
    getList(data = {}) {
      let param = { category: this.curType, flage: 1, ...data };
      getRecommendList(param).then(res => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleSearch(data) {
      this.getList(data);
    },
    handleEdit(item = {}) {
      this.currentItem = item;
      this.addEditShow = true;
    },
    handleDel(data) {
      if (data.length) {
        this.$confirm(`是否删除`, '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let ids = data.map(item => {
            return item.id;
          });
          deletRecommendByIds(ids).then(res => {
            this.$refs.GeneralTable.getList();
          });
        });
      }
    },
    handleExport(data) {
      let ids = data.map(item => {
        return item.id;
      });
      if (ids.length) {
        exportRecommendList(ids);
      }

    },
  }
}
</script>

<style>
</style>